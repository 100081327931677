


<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getReservationList" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담예약관리</h2>
                <p class="mb-10" style="font-size:14px;">※ 정렬 순서는 상담날짜 내림차순 입니다.</p>
                <p class="mb-10" style="font-size:14px;">※ 누적 시간은 전체 기간동안의 상담자별 모든 차트합산 예약(입실, NS) 시간입니다.</p>
                <div class="btns mb-30">
                    <a class="btn_new2 pointer" @click="consRegisterModal()">상담예약등록</a>
                </div>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                             <select class="w-100px h-40px mr-10" v-model="idxCrmCenter" @change="getReservationList()" :disabled="(isMaster || isAdmin) ? false : true">
                                <option value="-1" selected>센터선택</option>
                                <option value="0">본원센터</option>
                                <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>
                            <input type="text" @keyup.enter="getReservationList" v-model="value" class="w-200px h-40px pd-00 pl-20" placeholder="상담사명/고객명">
                            <a @click="getReservationList()" class="btn_search ml-10 pointer">Search<span></span></a>
                        </div>

                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div>상담날짜검색 :
                            <crm-datepicker v-model="consStartDate" :parentDate="consStartDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="consEndDate" :parentDate="consEndDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a class="btn_search pointer" @click="getReservationList()">Search<span></span></a>
                            <button type="button" class="down-btn ml-10" @click="excelDownload()">상세 내역 다운로드</button>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>센터</th>
                                <th>상담사</th>
                                <th>고객</th>
                                <th>연락처</th>
                                <th>상품구분</th>
                                <th>상품유형</th>
                                <th>누적회기</th>
                                <th>예약현황</th>
                                <th>상담형태</th>
                                <th>상담날짜</th>
                                <th>등록자</th>
                                <th>등록일</th>
                            </tr>
                            <tr>
                                <td colspan="14">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>센터</th>
                                <th>상담사</th>
                                <th>고객</th>
                                <th>연락처</th>
                                <th>상품구분</th>
                                <th>상품유형</th>
                                <th>누적시간</th>
                                <th>예약현황</th>
                                <th>상담형태</th>
                                <th>상담날짜</th>
                                <th>최종등록자</th>
                                <th>최종등록일</th>
                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of resList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{item.centerName}}</td>
                                <td class="underline pointer" style="color:blue" @click="staff(item.idxCrmStaff)">{{item.staffName}}</td>
                                <td class="underline pointer" style="color:blue" @click="client(item.idxCrmClient)">{{item.name}}</td>
                                <td>{{item.phone}}</td>
                                <td>{{item.gubunName}}</td>
                                <td>{{item.typeName}}</td>
                                <td>{{item.consCountUse}}</td>
                                <td>{{item.reservationStatus}}</td>
                                <td>{{item.contactType}}</td>
                                <td>{{dateCheck(item.consDate)}}</td>
                                <td>{{item.updateStaff}}</td>
                                <td>{{item.updateDate}}</td>
                                <td><span class="underline pointer" @click="detail(item.idx)">상세</span></td>
                            </tr>
                            <tr v-if="resList.length === 0">
                                <td colspan="14">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
        <register-modal ref="registerModal"  @modalFromChild="onReginsterCloseModal()" :onRegisterModal.sync="onRegisterModal.isShow" :consStartDate.sync="onRegisterModal.consDate" :idxCrmStaff.sync="onRegisterModal.idxCrmStaff" :staffName.sync="onRegisterModal.staffName" :time.sync="onRegisterModal.time" :onIdxCrmCenter.sync="onRegisterModal.idxCrmCenter" :userPersonIdx.sync="onRegisterModal.userPersonIdx"></register-modal>
    </div>
</div>
</template>

<script>
import FileSaver from 'file-saver';
import store from '@/store/index';
import registerModal from '@/pages/origin/consult/consultScheduleRegister.vue';
export default {
    data: () => ({
        consStartDate: '', // 기간검색-시작날짜
        consEndDate: '', // 기간검색-종료날짜

        resList: [],
        value: '',

        isMaster: false,
        isAdmin: false,

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
        centerList: [],
        idxCrmCenter: -1,
        onRegisterModal:{
            isShow:false,
            staffName : "",
            idxCrmStaff : 0,
            consDate : "",
            time : "",
            idxCrmCenter : 0,
            userPersonIdx : 0
        }
    }),
    components:{
        registerModal
    },
    mounted() {
        /* this.consStartDate = this.formatDate(), */

        if(sessionStorage.getItem('origin_reserve_pageNum') !== 'undefined' && sessionStorage.getItem('origin_reserve_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_reserve_pageNum'))
        }
        if(sessionStorage.getItem('origin_reserve_value') !== 'undefined' && sessionStorage.getItem('origin_reserve_value') !== null) {
            this.value = sessionStorage.getItem('origin_reserve_value')
        }
        if(sessionStorage.getItem('origin_reserve_consStartDate') !== 'undefined' && sessionStorage.getItem('origin_reserve_consStartDate') !== null) {
            this.consStartDate = sessionStorage.getItem('origin_reserve_consStartDate')
        }
        if(sessionStorage.getItem('origin_reserve_consEndDate') !== 'undefined' && sessionStorage.getItem('origin_reserve_consEndDate') !== null) {
            this.consEndDate = sessionStorage.getItem('origin_reserve_consEndDate')
        }


        if (localStorage.getItem('auth') === 'MASTER') {
            this.isMaster = true
        } else {
            this.isMaster = false
        }

        if(localStorage.getItem('auth') === 'ADMIN'){
            this.isAdmin = true;
        }
        else{
            this.isAdmin = false;
        }
        this.idxCrmCenter = store.state.userInfo.idxCrmCenter;
        this.getReservationList();
        this.getCenterListAll();
    },

    methods: {

        /**
         * @description  등록 모달 닫기
         */
        onReginsterCloseModal(){
            this.onRegisterModal.isShow = false;
            this.onRegisterModal.userPersonIdx = 0;
            // this.getChartList(this.idxCrmPerson);
        },

        /**
         * 
         * @description 상담 예약 모달 오픈
         */
        consRegisterModal(){
            let today = new Date();
            this.onRegisterModal.staffName = "";
            this.onRegisterModal.idxCrmStaff = 0;
            this.onRegisterModal.consDate  = today.getFullYear()+"-"+(today.getMonth() + 1) +"-"+today.getDate();
            this.onRegisterModal.time = "";
            this.onRegisterModal.idxCrmCenter = 0;
            this.onRegisterModal.userPersonIdx = 0;
            this.onRegisterModal.isShow = true;
            this.$refs.registerModal.getPersonList();
            this.$refs.registerModal.getClientList(this.idxCrmStaff);
        },


        dateCheck(date) {
            if (date !== null) {
                return date.slice(0,10)
            } else {
                return '-'
            }
        },

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getReservationList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getReservationList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getReservationList()
            }
        },

        // 상담일지 목록
        getReservationList() {
            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                value: this.value,
                consStartDate: this.consStartDate,
                consEndDate: this.consEndDate,
                idxCrmCenter: this.idxCrmCenter,
                orderType:'cons_date', //기본 정렬순서를 상담날짜 내림차순, 상담시간 오름차순
            }
            sessionStorage.setItem('origin_reserve_pageNum', this.pageNum)
            sessionStorage.setItem('origin_reserve_value', this.value)
            sessionStorage.setItem('origin_reserve_consStartDate', this.formatDate(this.consStartDate))
            sessionStorage.setItem('origin_reserve_consEndDate', this.formatDate(this.consEndDate))

            this.loading = true;
            this.axios.get('/api/v1/cons/list', {
                params: params
            })
            .then(res => {
                    if (res.data.err === 0) {
                        this.resList = res.data.resList

                        if (res.data.resListCount) {
                            this.listLength = res.data.resListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.resList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                    if(err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    }
                })
                .finally(()=>{
                    this.loading = false;
                });
        },

        // 상담예약 등록페이지로 이동
        register() {
            this.$router.push('/origin/consult_reserve_register')
        },
        // 상담예약관리 상세정보 페이지로 이동
        detail(idx) {
            this.$router.push(`/origin/consult_reserve_detail?idx=${idx}`)
        },

        // 상담예약 수정페이지로 이동
        modify (idx) {
            this.$router.push(`/origin/consult_reserve_modify?idx=${idx}`)
        },

        // 예약 삭제
        remove(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {

                this.axios.delete(`/api/v1/cons/${idx}`,
                    )
                    .then(res => {
                        if (res.data.err === 0) {
                            alert('삭제되었습니다.')
                            this.$router.go(this.$router.currentRoute)
                        } else {
                            alert(res.data.result)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        alert(err)
                    })
            } else {
                return false
            }

        },

         // 상담사 차트
        staff (idx) {
            this.$router.push(`/origin/counselor_manage_card?idx=${idx}`)
        },

         // 고객 차트
        client (idx) {
            this.$router.push(`/origin/customer_manage_chart?idx=${idx}`)
        },

        /**
         * @description : 센터 리스트 추출
         */
        getCenterListAll(){
            this.axios.get('/api/v1/center/all', {})
                .then(res =>{
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList;
                        const find = this.centerList.find(item=> item.idx === this.idxCrmCenter);
                        if(!find){
                            this.idxCrmCenter = store.state.userInfo.idxCrmCenter ;
                        }
                    }else{
                        this.centerList = [];
                        this.idxCrmCenter = store.state.userInfo.idxCrmCenter ;
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        /**
         * @description : 엑셀다운로드
         */
        excelDownload(){
            const params ={
                consStartDate: this.formatDate(this.consStartDate),
                consEndDate: this.formatDate(this.consEndDate),
                idxCrmCenter: this.idxCrmCenter,
                value: this.value,
                orderType:'cons_date', //기본 정렬순서를 상담날짜 내림차순, 상담시간 오름차순
            }


            this.axios.get('/api/v1/excel/reservation-list', {
                params: params,
                responseType: 'arraybuffer'
            }).then((res)=>{
                FileSaver.saveAs(new Blob([res.data]), "reservation-excel.xlsx");
            }).catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        }
    }
}
</script>

